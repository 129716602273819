import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'react-bootstrap'
import Layout from '~components/Layout'
import S from '~components/seo'
import { Pic } from './Pic'
import { Form } from '~components/Form'
import { Modal } from '~components/Modal'
import { talk, talk_content, talk_descr, talk_pic } from './style.module.scss'

export const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      contactJson {
        title
        descr
      }
    }
  `)
  const { title, descr } = data.contactJson

  const [isPopupOpen, setIsPopupOpen] = useState(null)
  const openPopup = () => setIsPopupOpen(true)
  const closePopup = () => setIsPopupOpen(false)
  return (
    <Layout>
      <S title="Talk to Us" />
      <Container className={talk}>
        <div className={talk_content}>
          <h1>{title}</h1>
          <p className={talk_descr}>{descr}</p>
          <div className={talk_pic}>
            <Pic />
          </div>
        </div>
        <Form variant="talktous" title="Talk To Us" openPopup={openPopup} />
        <Modal show={isPopupOpen} handleClose={closePopup} title="Let’s have a word" />
      </Container>
    </Layout>
  )
}
